import React from "react"
import { ThemeProvider } from "styled-components"

import theme from "./allSite/styles/Theme"
import GlobalStyle from "./allSite/styles/Golbal"

const Layout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </>
  )
}

export default Layout
