import * as secondary from "../../../../fonts/secondary"

const SecondaryFonts = `
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: local('Lobster Regular'), local('Lobster-Regular'),
       url('${secondary.WOFF2_4}') format('woff2'),
       url('${secondary.WOFF_4}') format('woff');
}
`

export default SecondaryFonts
