export const colorPrimary = "#E33D1F"
export const colorSecondary = "#FFF250"
export const colorTertiary = "#E33D1F"
export const colorAccent = "#FFF250"
export const colorSec1 = "#E33D1F"
export const colorSec2 = "#FFF250"
export const colorSec3 = "#E33D1F"
export const grey = "#65646A"
export const white = "#fff"
export const black = "#000"
export const strongRed = "#f00"

export default {
  colorPrimary,
  colorSecondary,
  colorTertiary,
  colorAccent,
  colorSec1,
  colorSec2,
  colorSec3,
  grey,
  white,
  black,
  strongRed,
}
