import * as primary from "../../../../fonts/primary"

const PrimaryFonts = `
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'),
       url('${primary.WOFF2_4}') format('woff2'),
       url('${primary.WOFF_4}') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: local('Raleway Italic'), local('Raleway-Italic'),
       url('${primary.WOFF2_4I}') format('woff2'),
       url('${primary.WOFF_4I}') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
       url('${primary.WOFF2_8}') format('woff2'),
       url('${primary.WOFF_8}') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
       url('${primary.WOFF2_8I}') format('woff2'),
       url('${primary.WOFF_8I}') format('woff');
}

`

export default PrimaryFonts
